<template>
  <div class="rounded-md px-4 py-3" :class="`alert-wrapper-${variant}`">
    <div class="flex items-center">
      <div class="shrink-0">
        <component :is="icon" v-if="icon" :class="iconClass" />

        <template v-else>
          <InformationIcon v-if="variant === 'info'" fill-color="#3e93ff" />
          <CheckboxMarkedCircleIcon
            v-if="variant === 'success'"
            fill-color="#15803d"
          />
          <AlertIcon v-if="showAlertIcon" :fill-color="alertIconColor" />
          <PauseCircleIcon v-if="variant === 'paused'" fill-color="#FF9C3D" />
        </template>
      </div>
      <slot v-if="$slots.content" name="content" />
      <div v-else class="mx-3 flex-1 md:flex md:justify-between">
        <p class="alert-text" :class="textClass"><slot /></p>
      </div>
      <slot v-if="$slots.button" name="button" />
    </div>
  </div>
</template>

<script lang="ts" setup>
type Props = {
  icon?: string
  iconClass?: string
  textClass?: string
  variant?: 'info' | 'error' | 'success' | 'danger' | 'warning' | 'paused' | ''
}

const { variant } = withDefaults(defineProps<Props>(), {
  variant: 'info'
})

const showAlertIcon = computed(() => {
  return variant === 'error' || variant === 'danger' || variant === 'warning'
})

const alertIconColor = computed(() => {
  switch (variant) {
    case 'error':
      return '#FF9C3D'
    case 'danger':
      return '#b91c1c'
    default:
      return '#FF9C3D'
  }
})
</script>

<style lang="scss" scoped>
.alert-wrapper-info,
.alert-wrapper-error {
  @apply bg-blue-50;

  .alert-text {
    @apply text-blue-700;
  }
}

.alert-wrapper-success {
  @apply bg-green-50;

  .alert-text {
    @apply text-green-700;
  }
}

.alert-wrapper-danger {
  @apply bg-red-50;

  .alert-text {
    @apply text-red-700;
  }
}
.alert-wrapper-warning,
.alert-wrapper-paused {
  @apply bg-signal-orange-100;

  .alert-text {
    @apply text-signal-orange-700;
  }
}
</style>
